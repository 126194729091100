import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/v2/Layout';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Grid, IconButton, InputBase, Paper, TableBody, TableCell, TableRow, Typography, Box, Button } from '@material-ui/core';
import { getEmoriList, postEmoriRelationDiary } from '../../../apis/emori';
import SummaryEmori from '../../../components/SummaryEmori';
import Table from '@material-ui/core/Table';
import { navigate } from 'gatsby';
import { getUrlParams } from '../../../helpers/location';
import { createEmoriProfileByIdSelector } from '../../../reducers/emori';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    backgroundColor: 'white',
    borderRadius: '0px 10px 10px 10px',
    paddingBottom: '22px',
  },
  emoriList: {
    marginRight: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
      width: theme.spacing(11.5),
      height: theme.spacing(2.75),
    },
    borderRadius: '12px',
  },
  emoriIcon: {
    width: '61px',
    height: '62px',
  },
  selectedEmoriIcon: {
    marginTop: '9px',
    width: theme.spacing(14.75),
    height: theme.spacing(14.625),
  },
  emoriText: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3px',
  },
  selected: {
    width: '80%',
    height: '40%',
    borderRadius: '29px',
  },
  dialog: {
    borderRadius: '29px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#3D6AAE',
  },
  feelingChoose: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#3D6AAE',
  },
  receive: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#3D6AAE',
  },
  goodText: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#3D6AAE',
  },
  tableCell: {
    padding: '1px 10px 0 10px',
    border: 0
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#F6F5F5',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  table: {
    maxWidth: '100%',
    marginBottom: '10px',
  },
}));

export default ({ location }) => {
  const params = getUrlParams(location.search);
  const { id, tab, profileId } = params;
  const profile = useSelector(state => createEmoriProfileByIdSelector(state, profileId));
  const { room } = useSelector(state => state.emori);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [ open, setOpen ] = React.useState(false);
  const [ openSuccess, setOpenSuccess ] = React.useState(false);
  const [ currentTab, setCurrentTab ] = React.useState();
  const [ note, setNote ] = React.useState('');
  const [ selected, setSelected ] = React.useState(null);
  const [ emoriList, setEmoriList] = React.useState(null);
  const [ successText, setSuccessText ] = React.useState('');

  React.useEffect(() => {
    emori();
  }, []);

  React.useEffect(() => {
    setCurrentTab(Number(tab || 0));
  }, [tab]);

  const emori = async () => {
    try {
      const res = await getEmoriList();
      const resList = res.results;
      await setEmoriList(resList);
      if (!!id) {
        const index = await (resList).findIndex(item => item.id === Number(id));
        const select = (resList)[index]
        await setSelected(select);
        await setOpen(true);
      }
    } catch (e) {}
  }

  const onClickEmori = (emoji) => {
    setOpen(true);
    setSelected(emoji);
  }

  const time = () => {
    const interval = setInterval(() => setOpenSuccess(false), 30000);
    return () => clearInterval(interval);
  }

  const checkGroup = () => {
    if (selected.group == 1) {
      setSuccessText('ขอให้วันนี้เป็นวันที่ดีนะ');
    } else {
      setSuccessText('หวังว่าคุณจะรู้สึกดีขึ้นนะ');
    }
  }

  const confirmEmori = async (emoji) => {
    checkGroup();
    try {
      let payload = { emori: selected.id, note: note };
      await postEmoriRelationDiary(room, profileId, payload);
      setOpen(false);
      setOpenSuccess(true);
      setNote('')
      await time();
    } catch (e) {
      alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
  }

  if (!emoriList) return false;

  const HandleClickHistory = (val) => {
    setOpenSuccess(false);
    navigate(`/emori/relation/chooseEmori?profileId=${profileId}&tab=${val}`)
  };

  return (
    <Layout bg='bg3' title='Emori'>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth='md' PaperProps={{ style: {borderRadius: '29px', marginBottom: '5px'}}}>
        <DialogContent>
          {
            selected ?
              <Table className={classes.table} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography className={classes.dialogTitle}>คุณรู้สึก...</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' className={classes.tableCell}>
                      <img className={classes.selectedEmoriIcon} src={`${selected.image}`} alt={`${selected.name}`}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' className={classes.tableCell}>
                      <p className={classes.feelingChoose}>{selected.name}</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' className={classes.tableCell}>
                      <Paper component='form' className={classes.root}>
                        <InputBase
                          className={classes.input}
                          placeholder='กดส่ง หรือ เล่าให้เราฟังเพิ่มได้นะ :)'
                          onChange={ event => { setNote(event.target.value) }}
                        />
                        <IconButton className={classes.iconButton} onClick={() => confirmEmori()}>
                          <img src={require(`../../../assets/images/arrowright.svg`)} alt='arrowRight'/>
                        </IconButton>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            : <></>
          }
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        fullWidth={true}
        maxWidth='md'
        PaperProps={{ style: {borderRadius: '29px', backgroundImage: 'url(/img/background.png)', backgroundSize: 'auto'}}}
      >
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='center' className={classes.tableCell}>
                  <img src={require(`../../../assets/images/jarSuccess.svg`)} alt='success'/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='center' className={classes.tableCell}>
                  <Typography className={classes.receive}>บันทึกอารมณ์ของคุณเรียบร้อย</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='center' className={classes.tableCell}>
                  <Typography className={classes.goodText}>{successText}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='center' className={classes.tableCell}>
                  <Button style={{ width: '50%', padding: 3, margin: 3 }} variant="contained" color="primary" size='large' onClick={() => HandleClickHistory(1)}>ดูประวัติ</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      <Typography gutterBottom style={{color:'white', fontSize: '18px'}}>{profile.name}</Typography>
      <div className={classes.paper}>
        <Grid container spacing={0} style={{ width: '100%', background: currentTab === 0 ? '#EFEEEE' : 'none', borderTopRightRadius: 10}}>
          <Grid item xs={6}>
            <div
              style={{
                background: currentTab === 0 ? 'white' : '#EFEEEE',
                color: currentTab === 0 ? '#4677a8' : '#D3D3D3' ,
                borderTopRightRadius: currentTab === 0 ? 10 : 0,
                textAlign: 'center',
                padding: 6
              }}
              onClick={() => HandleClickHistory(0)}
            >
              <span style={{ margin: 0, textAlign: 'center', fontSize: '13px' }}>สมุดบันทึกอารมณ์</span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                background: currentTab === 1 ? 'white' : 'none',
                color: currentTab === 1 ? '#4677a8' : '#D3D3D3' ,
                borderTopRightRadius: currentTab === 1 ? 10 : 0,
                textAlign: 'center',
                padding: 6
              }}
              onClick={() => HandleClickHistory(1)}
            >
              <span style={{ margin: 0, textAlign: 'center', fontSize: '13px' }}>ประวัติบันทึกอารมณ์</span>
            </div>
          </Grid>
        </Grid>
        {
          currentTab === 0 && (
            <Grid container spacing={1} style={{ marginTop: '20px'}}>
              {
                emoriList.map((emoji, index) => {
                  return (
                    <Grid item xs={4} key={index}>
                      <div className={classes.emoriList} onClick={() => onClickEmori(emoji)}>
                        <img className={classes.emoriIcon} src={`${emoji.image}`} alt={`${emoji.name}`} />
                        <Typography gutterBottom color='textPrimary' className={classes.emoriText}>{emoji.name}</Typography>
                      </div>
                    </Grid>
                  )
                })
              }
            </Grid>
          )
        }
        {
          currentTab === 1 && (
            <SummaryEmori relation={{room: room, profile: profileId}}/>
          )
        }
      </div>
    </Layout>
  )
}